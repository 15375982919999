/*
* @Author: Joe Steccato
* @Date:   2023-07-17 11:20:40
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 12:27:22
*/

.container {
  text-align: center;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  height: 78vh;
  letter-spacing: 0;
}

.trackTitle {
  color: black;
  margin: 6px 0px 6px 0px;
  font-size: 20px;
  padding: 0;
  position: relative;

  .selectedCount {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 14px;
    display: none;
  }
}

.tabContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  height: 78vh;
}

.taggerCopyButton {
  position: absolute;
  width: 120px;
  margin: 2px 10px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  top: 64px;
  right: 0%;
  cursor: pointer;
  z-index: 20;
}
