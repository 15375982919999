div.color_key{}

div.color_key div div span{
	font-size: 1.2rem;
	line-height: 1.6rem;
	padding: 20px 0px;
}

div.color_key div div{
	font-size: 0.9rem;
	padding: 12px 0px;
}

div#closePopup.instructionsClose{
	top: 10px;
    right: 18px;
}

ul.search_rules_ul{
	width: fit-content;
}

ul.search_rules_ul li{
	width: 90%;
	margin: 24px 10px;
	text-align: left;
	font-size: 1.1rem;
	letter-spacing: 0.010rem;
}