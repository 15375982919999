/*
* @Author: Joe Steccato
* @Date:   2023-04-20 15:04:39
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 13:14:51
*/

.container{
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	max-width: 700px;
}

.container h1{
	font-size: 20px;
}

.buttonRow{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 30px;
}

.or{
	width: 10%;
	margin: auto 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
}

.dropdown{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.typeButton{
	height: 34px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 40%;
	border: solid 1px #000;
	cursor: pointer;
	font-weight: 300;
	letter-spacing: 1.2px;
	font-size: 14px;
	font-weight: 500;
}

.typeButton.selected, .typeButton:hover{
	background-color: #1d252c;
	color:  #fff;
}

.typeButton:hover{
	text-decoration: underline;
}

