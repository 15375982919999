/*
* @Author: Joe Steccato
* @Date:   2023-04-20 16:37:29
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-12-13 15:06:30
*/

.container{
	height: 100%;
	font-size: 12px;
}

.header{
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 500;
	margin-top: 16px;
}

.content{
	margin: 20px auto 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.noTracks, .noFiles{
	height: 30px;
	padding-bottom: 0px;
	letter-spacing: 1px;
	font-weight: 300;
	display: flex;
	flex-direction: column;
	justify-content: center;

	margin: 0px auto;
	letter-spacing: 1px;
	font-weight: 300;
}

.noTracks{
	font-size: 16px;
}

.noFiles{
	background-color: rgb(245, 245, 245);
	font-size: 12px;
}

.tableHeader, .tableItem{
	text-align: left;
	word-wrap: break-word;
	margin-right: 6px;
	font-weight: 300;

	padding: 10px 30px;
	margin: 2px 0;
	font-weight: 300;
	letter-spacing: 1px;

	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.tableHeader{
	font-size: 10px;
}
.tableItem{
	font-size: 12px;
}


.tableTrack{
	background-color: rgb(245, 245, 245);
	margin: 2px 0px;
	cursor: pointer;
}

.tableHeader{
	background-color: #ececec;
	padding: 8px 30px;
	font-size: 10px;
	font-weight: 300;
	letter-spacing: 1px;
}

.name, .composer, .date, .viewButton{
	text-align: left;
	word-wrap: break-word;
	margin-right: 6px;
	font-weight: 300;
}

.name{
	width: 25%
}

.composer, .date{
	width: 40%;
}

.viewButton{
	width: 10%;
}

.fileList .name{
	width: 70%;
}

.fileList .date{
	width: 30%;
}

.trackDetails{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}


// inner file list
.fileList{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	margin-bottom: 10px;
}

.fileListHeader{
	margin-top: 10px;
	margin-left: 35px;
	text-align: left;
	font-weight: 500;
}

.fileList ul li{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 8px 0 0;
	letter-spacing: .3px;
}
