/*
* @Author: Joe Steccato
* @Date:   2023-04-10 16:43:39
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-11 10:56:56
*/


.container{
	color: white;

	width: 100%;
	margin: 20px auto 0 auto;	
}

.downloadStarted{
	font-size: 20px;
	width: 100%;
	padding: 16px 12px;
	background-color: #ffcf43;
	color: #1d252c;

	max-width: 500px;
	margin: 0 auto;
	text-align: center;
	vertical-align: middle;
}

.downloadButtons{
	margin: 40px auto;
	display: flex;
	flex-direction: column;
	width: 350px;
}

.downloadButtons a button{
	font-size: 16px;
	color: #1d252c;
	background-color: #d3d3d3;
	border: none;
	padding: 7px 15px;
	letter-spacing: 1.1px;
	cursor: pointer;
	font-weight: 300;
	margin-bottom: 10px;
	width: 100%;
}

.downloadButtons a button:hover{
	background-color: #ffcf43;
	color: #1d252c;
}