/*
* @Author: Joe Steccato
* @Date:   2023-04-20 16:08:46
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-20 17:26:49
*/


/* Previous File List */
.container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 90%;
	margin: 10px auto 30px auto;
	font-size: 12px;
	letter-spacing: 0.8px;
}

.fileHeader{
	margin: 8px 0px;
	font-weight: 700;
	display: flex;
}

.fileItem{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 8px 0px;
	font-weight: 300;
}

.name{
	width: 80%;
	text-align: left;
}

.date{
	width: 20%;
	text-align: right;
}
