.usersHeadingButtonsRow{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 75%;
	margin: 0 auto;
	margin-bottom: 15px;
}



button.editUserButton{
	font-size: 10px;
	color: white;
	background-color: #1d252c;
	border: none;
	padding: 3px 5px;
	letter-spacing: 1.1px;
	cursor: pointer;
	font-weight: 300;
}

button.editUserButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}

button.editUserButton.addUserButton{
	margin: auto;
	font-size: 12px;
	background-color: #fff;
	color: #1d252c;
	padding: 6px 10px;
}

button.editUserButton.addUserButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}

.usersHeader div span{
	font-size: 10px;
}

.usersHeader div:hover{
	color: #ffcf43;
	cursor: pointer;
}

.userListText {

}

.adminCheckbox{
	cursor: pointer;
}