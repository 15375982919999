/*
* @Author: Joe Steccato
* @Date:   2023-05-18 15:23:11
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-06-05 11:40:01
*/

.trackInputForm{
  overflow: scroll;
  height: 100%;
  border: solid 2px #d1d1d1;
  font-size: 14px;
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  min-width: 250px !important;
  padding: 0px 10px ;

  margin-left: 2px;
}

.trackInputForm.addTrack {
  background-color: #f6f6f6;
}

.trackInputForm.updateTrack {
  background-color: #e1e1e1;
  background-size: 28.28px 28.28px;
  border-color: #ffb570;
  overflow-x: hidden;
}


// Form Sections
.section{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0px 10px;
	text-align: left;
}

.inputLabel{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 0px;
	vertical-align: bottom;
}

.inputLabel span{
	height: 24px;
}

// Title & Composer
.inputLarge{
	height: 32px;
	width: 95%;
	padding: 2px 10px;
	font-weight: 300;
	border-radius: 7px;
	font-size: 18px;
	padding-left: 6px;
	flex-grow: 100;
}

.titleError{
	margin: 2px auto 6px;
	color: red;
	font-size: 11px;
}

// Collaborator
.collabLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 10px;
    padding: 6px 6px 12px 26px;
}

.collabLabel.open{
	width: 91%;
	flex-direction: column;
	margin: auto;
	padding: 6px 0px 0px;
}

.collabLabel hr{
	color: #1d252c;
	background-color: #1d252c;
	width: 90%;
}

.collabLabel:hover {
    text-decoration: underline;
}

.collabLabel input{

	height: 26px;
	font-weight: 300;
	border-radius: 7px;
	font-size: 14px;
	border: 2px solid #e3e3e3;
	padding: 2px 8px 2px 6px;
	padding-left: 10px !important;
}

.collabLabel div{
	font-size: 14px;
}

.collabLine{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 10px;
  opacity: 0.2;
  margin: 2px 0 -2px 0;
  font-size: 12px !important;
}

.collabLine:hover{
  opacity: 1;
  cursor: pointer; 
}


// Add Notes Button
.addNoteBtn{
	width: 31px;
	height: 24px;
	border-radius: 30px;
	font-size: 18px;
	color: #1d252c;
	padding: 6px 8px;
	line-height: 10px!important;
}


.addNoteBtn:hover{
  color: #fff;
  border-radius: 30px;
}


.inputNotes{
	height: 160px;
	width: 92%;
	border: 2px solid #e3e3e3;
	border-radius: 15px;
	overflow: hidden;
	margin-left: 6px;
	padding: 6px;
	margin-bottom: 30px;
}

@media screen and (min-width: 651px) and (max-width: 850px){
	.trackInputForm.addTrack{
		display: none;
	}
}


@media screen and (min-width: 0px) and (max-width: 650px){
	.trackInputForm.addTrack{
		display: none;
	}

	.trackInputForm.updateTrack{
		// width: 88% !important;
		// border-right: 0px solid #1d252c !important;
		// margin-right: 0px !important;
		width: 100%;
		overflow-y: scroll;
		height: 100%;
		height: inherit;
	}
}
