/*
* @Author: Joe Steccato
* @Date:   2023-04-26 11:24:22
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-15 16:33:14
*/

.track{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: solid 2px #d1d1d1;
	align-items: left;
	text-align: left;
	position: relative;
	cursor: pointer;
	height: 75px;
	transition: height 1s;
	transition: padding 1s;
	transition: font-size 1s;
	padding: 6px 3px;
}

.track:hover{
	opacity: 0.7;
}

.track.selected{
	border-color: #000;
}

.titleComposer{
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 6px 6px;
}

.title{
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 6px;
}

.composer{
	font-size: 12px;
}

.collab{
	font-size: 9px;
}

.isArk{
	background-color: #000000bb;
	color: orange;
	margin: auto;
	padding: 1px 3px;
	font-weight: bold;
	display: inline-block;
}

.submissionType{
	background-color: #1d252c33;
	width:90%;
	padding: 2px 4px;
	text-align: left;
}

.detailsDate, .detailsFile, .detailsStatus{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 8px 8px;
	width: 15%;
	font-size: 10px;
	overflow-wrap: anywhere;
}

.detailsStatus{
	font-size: 10px;
	height: 90%;
	margin: auto 0;
	justify-content: space-around;
}

.detailsFile{
	width: 30%;
	justify-content: space-around;
}

.detailsDate{
	width: 15%;
	text-align: right;
}

.versionDesc{
	font-size: 14px;
	font-weight: 500;
}

/// TRACK BUTTONS
.trackButtons{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	height: 30px;
}

.trackButtons.white svg{
	fill: #fff;
}

.trackButton{
  fill: #1d252c;
  opacity:0.4;
  cursor: pointer;
  margin-left: 3px;
}

.removeButton{
	width: 24px;
	position: relative;
	top: -4px;
}

.archiveButton {
  	width: 20px;
}

.trackButton svg:hover, .trackButton.white svg:hover{
  fill: red;
  opacity: 0.7;
}

