/* TRACKDETAIL */

@keyframes saveFlash {
  0% {background-color: #ffb570;top:0px;}
  50% {background-color: #fff;top:10px;}
  100% {background-color: #ffb570;top:0px;}
}

label:hover{
  text-decoration: underline;
  cursor: pointer;
}

div.trackInputForm{
  overflow: scroll;
  height: 100%;
  border: solid 2px #d1d1d1;
  font-size: 14px;
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  min-width: 250px !important;
  padding: 0px 10px ;

  margin-left: 2px;
}

div.trackInputForm.addTrack {

  background-color: #f6f6f6;
}

div.trackInputForm.updateTrack {
  background-color: #e1e1e1;
  background-size: 28.28px 28.28px;
  border-color: #ffb570;
  overflow-x: hidden;
}

.updateButton {
  animation-name: saveFlash;
  animation-duration: 1.2s;
  animation-iteration-count: infinite; 
}

.updateButton:hover {
  animation: none !important;
}

.savedSpan {
  font-weight: 500;
  letter-spacing: 2px;
  color: #d2d2d2;
}

.inputRow.inputSmall{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 20px;
}

div.Dropdown-root{
  width:  100%;
}

/*div.versionDD.Dropdown-root {
  width: 260px;
}

div.versionDD.Dropdown-root.Dropdown-control.Dropdown-placeholder{
  height: 200px;
}

div.Dropdown-control.versionDD {
  width: 97%;
}*/

.inputCurrentVersion{
width: auto;
}

.inputSmall div{
  padding: 0 10px;
}

.inputSmall div input{
  max-width: 70px;
  width: 40%;
  margin-top: 6px;
  font-size: 14px;
  padding: 4px;
}

input{
  font-size: 20px;
  border: 2px solid #e3e3e3;
  border-radius: 7px;
  width: 100%;
  padding-left: 6px;
}

.inputSmall div input.inputKey{
  max-width: 50px;
}

input.inputSmall{
  max-width: 50px;
  margin-right: 20px;
}

input.inputRequest{
  width: 100%;
  height: 26px;
  font-size: 14px;
  font-weight: 300;
  padding-right: 10px;
}

div div.editVersionLabel{
  width: 60px;
  margin-top: 4px;
  margin-right: 6px;
  padding: 2px 0px;
}

label#respondedSpan{
  width: auto;
  padding: 4px 6px;
}

label#respondedSpan input {
  width: auto !important;
  min-width: 0px !important;
  margin-right: 6px !important;
}

.checkBoxDiv.respondedLabel {
    padding-left: 6px;
    padding-right: 12px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#statusLabel{
  padding: 0px 12px 0px 6px;
  margin-left: -4px;
}

#statusLabel, .checkBoxDiv.respondedLabel {
  border-radius: 15px;
  }

.formHeader{
  margin: 0 20px 10px 20px;
  position: relative;
  padding-bottom: 0px;
  border-bottom: 0.5px solid #b7b7b7;
  padding-bottom: 10px;
}

.formHeader h2{
  padding-bottom: 8px;
  margin-block-end: 0.2em;
  margin-block-start: 0.5em;
}

.trackAddButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.savePopup{
  font-size: 8px;
  letter-spacing: 0.6px;
  height: 10px;
  color: red;
  text-decoration: underline;
  padding-top: 15px;
}

.trackInputForm h2{
  color: #1d252c;
  padding-top: 10px;
}


.inputContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 88%;
  padding-bottom: 30px;
}

.inputRow{
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 10px 0px;
}

.inputRow.inputRowEnd{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inputColumn{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  text-align: left;
  align-items: left;
}

.inputColumn .chkboxColumn {
  display: flex;
  flex-direction: row; 
  justify-content: space-around;
  padding-top: 10px;
}

.showMoreActive .inputColumn{
  padding-top: 6px;
  padding-bottom: 4px;
}

.inputRow.feeHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 10px 0;
}

.inputRow.feeHeader span.feeLabel{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.inputRow.inputStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0px;
}

textarea.inputNotes{
  height: 160px;
  width: 92%;
  border: 2px solid #e3e3e3;
  border-radius: 15px; 
  overflow: hidden;
  margin-left: 6px;
  padding: 6px;
  margin-bottom: 30px;
}


textarea.inputNotes.inputFilenames{
  height: 60px;
  margin-bottom: 10px;
}

textarea.inputNotes.inputFeeNotes{
  height: 30px;
  margin-bottom: 0px;
}

.inputRow div{
/*  padding: 4px 4px 4px 0px;
*/    display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputRow div.Dropdown-menu{
/*  padding: 4px 4px 4px 0px;
*/    display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputRow span{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
}

div.feeContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

div.feeContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100px;
  vertical-align: middle;
  align-items: center;
}

div.feeContainer:nth-child(2){
  margin-right: 4px;
}

.inputNotes {
  display: flex;
}

input.inputFeePaid{
  width:20px;
  font-size: 14px !important;
}

input.inputFee{
  margin-left: 0px;
  margin-top: 0px !important;
  width: 50px !important;
}

.inputColumn div.formNotesHeader {
  padding: 0px 0px;
  margin-bottom: -6px;
}

div.notesHeader, div.notesHeader div {
  padding: 10px 0 0px 0px;
}

.trackButton {
  width: 50%;
}

div.trackId {
  font-size: 8px;
}

.clearSearch svg{
  position: absolute;
  top: 13px;
  right: 28px;
  width: 24px;
  z-index: 3;
  fill:#1d252c;
  opacity:0.4;
  cursor: pointer;
}

.clearSearch.searchButton svg{
  right: 65px;
  width: 34px;
  top: 8px;
}

.clearSearch svg:hover{
  opacity:1;
  fill: red !important;
  opacity: 0.7 !important;
}

.clearSearch.searchButton svg:hover{
  opacity:1;
  fill: #ffcf43 !important;
  opacity: 0.7 !important;
}

span.closeButton span .svg-class-name{
  width: 24px;
  fill: #1d252c;
  opacity:0.4;
  cursor: pointer;
}

.svg-class-name:hover{
  fill: red !important;
  opacity: 0.7 !important;
}

.addRevisionBtn.btn-flat{
  width: 31px;
  height: 24px;
  border-radius: 30px;
  font-size: 18px;
  color: #1d252c;
  padding: 6px 8px;
  line-height:10px!important;
}

.addRevisionBtn.btn-flat:hover{
  color: #fff;
  border-radius: 30px;
}

.notesHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px !important;
}

.notesHeader span{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.svg-arrow{
  width: 20px;
  cursor: pointer;
  stroke: #1d252c;
}

.svg-arrow span svg:hover{
  stroke: #ffcf43 !important;
}

.svg-arrow-left {
  position: absolute;
  top:4%;
  left: 11%;
}

.svg-arrow-right {
  position: absolute;
  top:4%;
  left: 82%;
}

.checkBoxDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

#statusLabel, .checkBoxDiv.respondedLabel{
    -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
    transition: background-color 1s;
} 

.updateBtnSpacer{
  padding: 6px 16px;
  margin-top: 33px;
}

div.showMore {
  border-radius: 15px;
  background-color: #1d252c50;
  color: #000;
  width: 90%;
  margin: 15px auto 15px auto;
  padding: 10px 0px 10px 0px;
}

div.showMore div.showMoreText {
  padding: 5px 0px 5px 0px;
}

div.showMore.showMoreActive div.showMoreText{
  padding: 5px 0px 10px 0px;
}

div.showMore div.showMoreText:hover{
 
  text-decoration: underline !important;
  cursor: pointer;
}

div.inputContainer{
  padding: 10px 10px;
  scrollbar-color: dark;
}

/* export files popup  */
.mp3File{
  color: red !important;
}

span.linkButton {
    position: absolute;
    top: 10px;
    right: 30px !important;
    width: 18px;
    fill: #1d252c;
    opacity: 0.4;
    cursor: pointer;
}

span.starButton {
    position: absolute;
    top: 10px;
    left: 30px !important;
    width: 18px;
    fill: #00000000;
    stroke: #414141;
    opacity: 0.6;
    cursor: pointer;
}

span.starButton:hover svg, span.isStarred svg{
  fill: #ffcf43ff;
  stroke: #414141;
}
