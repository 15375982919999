body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ck-content, .ck-editor.ck-reset.ck, .ck-editor__main{
  height: 55vh;
}

