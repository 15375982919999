/*
* @Author: Joe Steccato
* @Date:   2023-07-17 13:01:20
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 13:04:27
*/

.container{
	color: white;

	h1{
		font-size: 30px;
	}
}

.go_back{
	color: white;
	text-decoration: none;
	cursor: pointer;

	&:hover{
		text-decoration: underline; 
	}
}

