/*
* @Author: Joe Steccato
* @Date:   2023-04-20 11:28:37
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-16 14:08:51
*/

.mainForm{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;

	width: 100%;
	max-width: 500px;
	margin: 0px auto;
	padding: 0px 20px;
}

.formRow{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 4px;
}

.formInput{
	padding: 8px 10px;
    width:  auto;
    border-radius: 0px !important;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
}

.formInput.formDropDown{
	width: 100%;
	max-width: 100% !important;
	margin: 0 auto 0 auto;
	display: block !important;
	font-size: 12px;
	padding: 0px;

	height:  auto !important;
}

.formInput#trackBPM{
	width: 30px;
}

.formLabel{
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-left: 4px;
    margin-bottom: 2px;
    color: #757575;
    width: 100%;
    text-align: left;
    position: relative;
}


.astericks{
	margin-left: 1px;
	top: 1px;
	font-size: 16px;
	position: absolute;
	font-weight: 500;
}

.formField{
	display: flex;
	flex-direction: column;
	margin-bottom: 18px;
	width: 100%;
	letter-spacing: 1px;
}

.formField.error input{
	border-color:  red !important;
}

.formField.small{
	width: 28% !important;
}

.create .formField .lock{
	display: none;
}

.update .formField .formInput{
	background-color: #efefef;
}

.errorMsg{
	color: red;
	font-size: 12px;
	margin: 8px auto 10px;
	letter-spacing: 1px;
	text-align: center;
}

.lock{
    font-size: 14px;
    position: relative;
    top: -25px;
    left: 95%;
    z-index:20;
    width: 24px !important;
}

.autocomplete{
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 1.2px;
	margin: 4px 0px 10px;
	color: rgb(117, 117, 117);
	text-align: center;
}


// TAGGER
.tagsContainer{
	width: 100%;
}

.viewAllInstruments {
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #757575;
    width: 100%;
    text-align: left;
    text-decoration: underline;
}

.viewAllInstruments:hover{
    color: #ffcf43;
    cursor:  pointer;
}


// NOTES
.noteInput{
	 width: 100% !important;
	 border-radius: 0px;
	 font-size: 14px;    
	 border: 1px solid #ACACAC !important;
	 font-family: "Gotham SSm A", "Gotham SSm B" !important;
	 font-weight: 300;
	 letter-spacing: 1px;
	 min-height: 80px;
	 width: 97% !important;
	 padding: 6px;
}

.comment{
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 1.2px;
	margin-left: 4px;
	color: rgb(117, 117, 117);
	text-align: center;
	margin: 6px 0px 30px 4px;
}