.popup_inner.composer_popup_inner{
	border-radius: 0px;
	border: 0px #000 solid;
	min-width: 700px;
	min-height: 300px;
	height: auto;
	top: 20%;
	background-color: #1d252c;
	justify-content: flex-start;
	color: #fff;
	font-weight: 300;
	letter-spacing: 1px;
	padding: 20px 40px;
}

.popup_inner.composer_popup_inner h2{
	letter-spacing: 1px;
	font-weight: 500;
	font-size: 20px;
	margin-top: 50px;
	margin-bottom: 30px;
	color: #ffcf43;
}

.composerSubmitTitle{
	font-weight: 500;
	margin-bottom: 30px;
}

.popup_inner.composer_popup_inner div#closePopup{
	right: 5%;
}

.popup_inner.composer_popup_inner div#closePopup:hover{
	color: #fff;
}

div.composerSubmitFileList{
	max-height: 30vh;
	overflow-y: scroll;
}

div.composerSubmitFileList::-webkit-scrollbar {
    width: 8px;
}

div.composerSubmitFileList::-webkit-scrollbar-track {
    background-color: #00000000;
    border-radius: 10px;
}

div.composerSubmitFileList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff66;
}

button.composerPopupOK{
	position: relative;
	margin-top: 20px;
	border: #fff solid 2px;
	background-color: #1d252c;
	color: #fff;
	font-size: 16px;
	padding: 10px 50px;
	cursor: pointer;
}

button.composerPopupOK:hover{
	background-color: #ffcf43;
	color: #1d252c;
	border: #ffcf43 solid 2px;
}

div.submitPopupFile{
	margin: 10px 0;
	font-size: 14px;
	font-weight: 300;
}