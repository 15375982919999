/* REACT TAGS */
.ReactTags__tagInputField{
    opacity: 0.6;
}

.react-tags-wrapper{
    width: 99% !important;
    border: 1px #ACACAC solid !important;
    min-width: 160px !important;
    background-color: white;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: auto;
    border-radius: 2px;
    display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 26px;
    margin: 0;
    font-size: 12px;
    width: 200px;
    border: 1px solid #eee;
    padding: 6px 8px;
    border: 0px solid #000 !important;
    font-size: 12px;
    letter-spacing: 1px;
}

div.ReactTags__selected{
    margin: 0 0px;
}

div.composerInfoUpdate div.ReactTags__selected span.ReactTags__tag {
    opacity: 0.6 !important;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    font-size: 10px !important;
    display: inline-block;
    padding: 5px 7px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
    margin: 3px 3px;
    background-color: #1d252c;
    color: #fff;
    font-size: 10px !important;
    font-weight: 300;
    letter-spacing: 1px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

button.ReactTags__remove {
	border: 0px #000 solid;
	cursor: pointer;
	font-size: 12px;
	background-color: #1d252c;
	color: #fff;
	font-size: 14px;
}

button.ReactTags__remove:hover {
	color: #f00;
}	

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
	margin-top: 0 !important;
	z-index: 300 !important;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
    margin-top: 0 !important;
    padding-left: 0 !important;
    font-size: 12px !important;
    z-index: 300 !important;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
    font-size: 12px !important;
    z-index: 300 !important;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

/* DROPDOWN */
.Dropdown-root{
    width: 100%;
    max-width: 100% !important;
    margin: 0 auto 0 auto;
    display: block !important;
    font-size: 12px;
}

.Dropdown-root.error .Dropdown-control{
    border-color: red;
}

div.Dropdown-placeholder, .Dropdown-option{
    height:  auto !important;
    padding: 8px !important;
    font-size: 12px!important;
    letter-spacing: 1px;
    border-radius: 0;
    margin: 0;
}

.Dropdown-option{
    font-size: 12px !important;
}

.Dropdown-disabled{
    background-color: #efefef;
}

.Dropdown-disabled .Dropdown-arrow{
    opacity: 0;
}

.Dropdown-placeholder{
    height: 34px;
    margin: auto 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.Dropdown-placeholder{
    margin:  0 !important;
    padding: 8px 5px 8px 10px !important;
}

.Dropdown-option{
    font-size: 12px !important;
    height: auto;
    margin: 0;
}

.Dropdown-option:hover{
    background-color: #1d252c !important;
    color: #ffcf43;
}

.Dropdown-menu{
    font-size: 12px !important;
}