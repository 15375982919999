/*
* @Author: Joe Steccato
* @Date:   2023-04-19 10:25:22
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-19 10:40:04
*/

// SUBMISSIONS PAUSED POPUP

.popupOuter{
	height: 100vh;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.667);
}

.popupInner{
	position: absolute;
    top: 30vh;
    left: calc(50vw - 250px);
    width: 500px;
    background-color: rgb(255, 255, 255);
    height: 33vh;
    color: rgb(0, 0, 0);
}

.close{
	font-size: 18px;
}

.header{
	padding: 30px 30px 10px;
    font-weight: 500;
    font-size: 20px;
}

.message{
	padding: 20px 30px;
	font-weight: 300;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.message div{
	margin-bottom: 18px;
}