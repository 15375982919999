/*
* @Author: Joe Steccato
* @Date:   2023-05-18 16:32:55
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-18 16:51:46
*/

.row{
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 90%;
	margin: auto auto 14px;
}

.label{
	margin-bottom: 1px;
	font-size: 12px;
	margin-right: auto;
}

.row input{
	margin: auto;
	height: auto;
	width: 94%;
	padding: 8px !important;
	font-size: 12px!important;
	letter-spacing: 1px;
	border-radius: 0;
}

.rowCheckbox{
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	font-size: 12px;
	margin-bottom: 20px;
}

.rowCheckbox input{
	width: 20px;
}