/*
* @Author: Joe Steccato
* @Date:   2023-04-20 17:38:45
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-20 17:50:17
*/

.appFooter{
	background-color: #1d252c;
	height: 30px;
	padding: 6px;
	color: white;
	font-weight: 700;
	font-size: 30px;
	margin: auto 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

}

.copyright{
	margin: auto;
	font-size: 10px;
	font-weight: 300;
	color: #ffffffbb;

}

@media screen and (min-width: 0px) and (max-width: 600px){
	.appFooter{
		display: none;
	}
}