.loadingBars{
  display: flex;
  justify-content:center;
  align-content:center;
  flex-direction: column;
  width: 100%;
  font-size: 30px;
  margin: auto auto;
  border-collapse: separate;
  perspective: 1px;
  height: 98%;
  overflow-x: hidden;
}

.loadingBars span{
  text-align: center;
  margin: auto;
}