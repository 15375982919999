.composerForm{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height:  100%; 
}

.composerFormMain{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	height:  calc(100% - 60px);
	overflow-y: scroll;
}

.composerFormMain.list{
	height: 100%;
}

.formContainer{
	margin: 0px auto;
	width: 70vw;
	max-width: 1000px;
	min-width: 700px;
}

.mainForm{
	margin: 0 auto;
	max-width: 700px;
	min-width: 700px;
}

.inputColumn{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0px 20px;
	text-align: left;
}