.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/* POPUP BASIC */
.popup {  
  position: fixed;  
  width: 100%;  
  height: 100%;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  margin: auto;  
  background-color: rgba(0,0,0, 0.5);  
  z-index: 100;
}  


.popup_inner {  
  min-width: 700px;
  width: fit-content;
  height: auto;
  max-height: 95%;
  position: absolute;  
  left: 25%;  
  right: 25%;  
  top: 5%;  
  margin: auto;  
  border-radius: 20px;  
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 6px #d1d1d1;
  background-color: #f6f6f6;
}

.track_tagger_page{  
  min-width: 700px;
  width: fit-content;
  height: auto;
  max-height: 100%;
  left: 25%;  
  right: 25%;  
  top: 5%;  
  margin: auto;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 0px #d1d1d1;
  background-color: #f6f6f6;
  overflow-y: scroll;
  padding: 0px 10px;
}

h1{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.filePopupList{
  margin: 18px auto;
  width: auto;
  align-content: center;
  overflow-y: auto;
  background: white;
  padding: 6px;
  border-radius: 20px;
    display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.fileItem{
  padding: 0px;
  text-align: left;
  font-size: 12px;
  border-bottom: 0.2px #e5e5e5 solid;
}
.fileItem:first-child{
  border-bottom: 0.2px #a1a1a1 solid;
}

.fileItem:first-child span.fileInfo{
  font-size: 20px;
  text-align: center;
  color: black;
  font-variant-caps: small-caps;
}

.fileItem:last-child{
  border-bottom: none !important;
}

span.fileInfo{
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-width: 200px;
}

.fileInfo div:first-child{
  width: 100%;
}

#closePopup{
  cursor: pointer;
  position:absolute;
  top: 3%;
  right: 3%;
  font-size: 24px;
  color: #a3a3a3;
}

button#download{
  margin: 0 auto;
}

#closePopup:hover{
  color: #000;
}

.requestForm div.requestFormInput{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6px;
  vertical-align: middle;
  height: 30px;
  width: auto;
}

.requestForm div.requestFormInput span.requestLabel{
  height: auto;
  margin: auto 0;
  text-align: right;
}

.requestForm div span{
  width: 100px;
  font-weight: bold;
}

.requestForm input{
  margin-left: 8px;
  margin-right: 16px;
  width: auto;
  font-size: 14px;
  padding: 3px 4px;
  min-width: 200px;
  width: auto;
}

.requestForm input[type='checkbox']{
  margin:auto;
  margin-right: 40px !important;
  font-size: 14px;
  padding: 3px 4px;
  min-width: 30px;
}

.requestForm div.requestBody{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}

.requestForm div.requestBody textarea{
  width: 600px;
  height: 100% ;
  min-height: 300px;
}

.exportFileItem{
  width:  50vw;
}

.exportFileItem:hover{
  background-color: #ffcf4333;
}

.exportFileItemSelected{
  background-color: #ffcf4399;
}

.exportFileItem.exportFileItemSelected:hover, .exportFileLastSel {
  background-color: #ffcf43 !important;
}