@media screen and (min-width: 0px) and (max-width: 600px){
  html{
    background-color: #1d252c;
  }
  input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px !important;
    resize:none;
  }
  .App {
    height: 90vh !important;
    scroll-behavior: smooth !important;
  }

  .main{
    overflow:hidden;
    scroll-behavior: smooth !important;
    height: 90vh !important;
  }

  .body{
    overflow: hidden;
    height: 90vh;
  }
  
/* MOBILE LOGIN */
  .loginInputContainer{
    height: 100%!important;
  }
  .loginContainer div.loginBox{
    z-index: 500;
    width: 96% !important;
  }
  input.inputLogin {
    width: 70% !important;
  }

  /* MOBILE TRACK FORM */
  .inputColumn input{
    min-width: 10%;
  }
  textarea.inputNotes{
    margin-bottom: 100px;
  }
  .thisTrackDate, .thisTrackTime, .trackStatusDetails{ display: none !important; }
}

div.keywordItem:hover{
  text-decoration: underline;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* APP */
.hide {
  display: none;
}

.App{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: #1d252c;
  background-color: #1d252c;
  font-family: "Gotham SSm A", "Gotham SSm B" !important;
  overflow: hidden !important;
}

button, input{
  font-family: "Gotham SSm A", "Gotham SSm B" !important;

}

.App-body {
  height: calc(100vh - 56px);
}

.App-body:not(:root:root){
    height: calc(100vh - 56px);
}

input{
  letter-spacing: 1px;
  border: 1px solid #ACACAC !important;
}

/* BUTTONS */

.btn-flat {
  width: 100px;
  border-radius: 4px;
  padding: 6px 16px;
  border-color: #1d252c;
  color: #1d252c;
  background-color: white;
  text-align: center;
  border: 2px solid #e3e3e3;
  border-radius: 7px;
}

.btn-flat:hover {
  background-color: #1d252c;
  color: #fff;
  cursor: pointer;
  border: 2px solid #1d252c;
  border-radius: 7px;
}

.Dropdown-control{
  text-align: left;
  padding:  0 !important;  
  border: 1px solid #acacac;
}

.Dropdown-option{
  border-bottom: 0.5px solid #f2f2f2;
  text-align: left;
}