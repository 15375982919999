/*
* @Author: Joe Steccato
* @Date:   2023-04-07 14:58:52
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-10 12:40:40
*/

.fileUploads{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto 10px auto;
	height: 26px;
}

.fileUpload{
	// position: relative;
	// top: 20px;
	font-size: 12px;
	width: 90%;
	display: flex;
	flex-direction: row;
	margin: auto;
	height: 26px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 8px;
	padding-top: 4px;
	// pointer-events: none;
}

.uploadInner{
	width: 90%;
	height: 26px;
}

.uploadRow{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.fileName{
	width: 100%;
	margin-left: 10px;
	z-index: 5;
	position: relative;
	top: 2px;

}

.progressLine{
	position: relative;
	top: -20px;
	z-index: 2;
	height: 26px;
	width: 100%;
}

.percent{
	width: 7%;
	text-align: center;
	height: 100%;
	margin: auto 0;
	position: relative;
}

.deleteColumn{
	width: 3%;
	height: 100%;
	text-align: right;
	position: relative;
}

.duplicateOptions{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.downloadIcon{
	// text-align: right !important;
	cursor: pointer;
}

.downloadCancelButton, .failedFilesPopupCancelButton{
	color: #00000066;
	cursor: pointer;
	pointer-events: all;
}

.downloadCancelButton:hover, .failedFilesPopupCancelButton:hover{
	color: red;
}


