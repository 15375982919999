/*
* @Author: Joe Steccato
* @Date:   2023-05-12 14:27:39
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-12 15:45:53
*/


.mobile{
	background-color: #fff;
	border-radius: 8px;
}

.mobile .header{
	text-decoration: underline;
	padding-left: 14px;
}

.mobile .latestFilename{
	text-align: center;
}