/*
* @Author: Joe Steccato
* @Date:   2023-07-17 11:20:40
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 12:09:21
*/

.container {
  text-align: left;
  font-size: 16px;
  margin-bottom: 12px;

  b u {
    text-decoration: underline;
  }

  &.genreContainer {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-flow: column wrap;
    height: calc(100vh - 200px);
  }

  &.defaultContainer {
    width: 130px;
    margin-bottom: 20px;
  }

  .keywordItem {
    padding: 0 2px;
    font-size: 12px;
    cursor: pointer;

    .keywordText {
      padding: 0px 2px;
      font-size: 12px;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .keywordItem.selectedItem {
  	.keywordText {
  	  background-color: #ffcf43;
  	}
  }
}
