/*
* @Author: Joe Steccato
* @Date:   2023-04-26 11:01:03
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-06-05 11:33:12
*/


.trackList{
	width: 66%;
	border-radius: 15px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-grow: 1;
}

.trackSearchContainer{
	height: auto;
}

.tracksListContainer{
	display: block;
	flex-grow: 2;
	overflow: auto;
	background-color: #d1d1d1;
}

.tracksWrap.trackListWrap{
	border-collapse: separate;
	perspective: 1px;
	height: 100%;
	overflow-x: hidden;
}


@media screen and (min-width: 651px) and (max-width: 850px){}


@media screen and (min-width: 0px) and (max-width: 650px){
  .trackList{
  	width: 96%;
  }

  .trackList.selected{
  	display: none;
  }
}
