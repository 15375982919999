/*
* @Author: Joe Steccato
* @Date:   2023-04-21 10:44:57
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-23 11:07:56
*/

.appHeader {
  background-color: #1d252c;
  height: 56px; 
  -webkit-height:76px; 
  -o-height:76px; 
  -moz-height:76px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerItem{
	width: 200px;
	margin: auto;
}

.headerMiddle{
	flex-grow: 2;
	margin: auto;
}

.hamburgerButton{
  text-align: left;
  // padding-left: 26px;
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hamburgerButton svg{
  width: 26px;
  height: 26px;
  margin-right: auto;
  fill: #b0b0b0;
}

.hamburgerButton svg:hover{
    fill: white;
    cursor: pointer;
}

.menuOpen .hamburgerButton svg{
    fill: #1d252c;
    cursor: pointer;
}

.burger{
  width: 56px;
  height: 56px;
}

.menuOpen .hamburgerButton{
  background-color: #f6f6f6;
}

.logo{
	height: 40px;
	max-height: 40px;
}

.logout{
  margin: 4px;
  font-size: 12px;
  cursor: pointer;
}

.logout:hover{
  text-decoration: underline;
}

.email{
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 1px;
}


@media screen and (min-width: 0px) and (max-width: 600px){
  .logout, .email{
    display: none;
  }
}
