/*
* @Author: Joe Steccato
* @Date:   2023-08-01 11:58:25
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-08-07 11:07:55
*/


.content{
	color: #fff;
}


.header{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 75%;
	margin: 0 auto;
	margin-bottom: 15px;
	max-width: 1000px
}

.headerLeft, .headerRight{
	width: 30%;
	margin: auto;
}

.headerMain{
	width: 40%;

	a{
		color: #ff0000;
		font-size: 10px;
	}

	h1{
		font-size: 20px;
	}
}

.headerRight{
	text-align: right;
}

button.editUserButton{
	font-size: 10px;
	color: white;
	background-color: #1d252c;
	border: none;
	padding: 3px 5px;
	letter-spacing: 1.1px;
	cursor: pointer;
	font-weight: 300;
}

button.editUserButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}

button.editUserButton.addUserButton{
	margin: auto;
	font-size: 12px;
	background-color: #fff;
	color: #1d252c;
	padding: 6px 10px;
}

button.editUserButton.addUserButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}


.main{
	width: 75%;
	max-width: 1000px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}


.listHeader{
	width: 100%;
	margin: 0 auto;
	display: flex; 
	flex-direction: row; 
	justify-content: flex-start;
	color: #fff;
	padding: 6px 0;
	background: #000;
	border-bottom: 1px solid #000;
}

.listHeader div span{
	font-size: 10px;
}

.listHeader div:hover{
	color: #ffcf43;
	cursor: pointer;
}

.listData{
	overflow-y: auto;
	height: calc(100vh - 250px);
	overflow-x: hidden;
	background-color: #fff;
}

.userListText {

}

.adminCheckbox{
	cursor: pointer;
}


.user{
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	display: flex;
	flex-direction: row,;
	justify-content: flex-start;
	color: #000;
	padding: 4px 0px;
	border-bottom: 1px solid #000;
	background-color: #fff;


}

.isAdmin{
	background-color: #ffcf4333;
}


.userListText{
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 1px;
	margin: auto auto;
	padding: 3px 0px;

}