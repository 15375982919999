/*
* @Author: Joe Steccato
* @Date:   2023-04-25 20:31:21
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-06-05 11:27:48
*/


.sidebar{
	width: 180px !important;
	height: 100%;
	margin-right: 2px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	border: solid 2px #d1d1d1;
	background-color: #f6f6f6;	
}

.sidebar.closed{
	width: 20px;
	min-width: 20px;
}

.item{
	width: 90%;
	max-width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	padding: 5px 0px 5px 0px;
}

.dropdown{
	width: 100%;
}

.radioLabel{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	text-align: center;
	padding-bottom: 3px;
	width: 90%;
	font-size: 12px;
	max-width: 120px
}

.radioButton{
	width: 12px;
	margin-right: 4px;
	margin-top: 0px;

	margin-right: 1.5px;
	margin-bottom: 6px;
	-webkit-appearance: checkbox;
}

.counter{
	width: auto;
	min-width: 120px;
	background-color: #fff;
	border-radius: 15px;
	padding: 8px 0!important;
	border: 2px solid #e3e3e3;
	margin-bottom: 3px;
	font-size: 12px;
}

.numContainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.counterNum{
	font-size: 14px;

	margin-top: 8px;
	text-align: center;
	font-weight: 700;
	padding: 11px 8px;

	height: 15px;

	color: #fff;
	
	border-radius: 30px;
	border: 2px solid #e3e3e3;
	min-width: 20px;
}

.counterNum.blue{
	background-color: #366ae4;
}

.counterNum.green{
	background-color: green;
}

.item button{
	width: 120px;
	margin-bottom: 8px;
	font-size: 12px;
}


// OPEN CLOSE ARROW
.openArrow{
	width: 40px;
	position: absolute;
	top: 53%;
	left: 138px;
	opacity: 0.6;
	font-size: 18px;
}

.openArrow span{
	padding: 4px 10px;
}

.openArrow:hover{
	opacity: 1;
	cursor: pointer;
}

.closed .openArrow{
	width: 40px;
	position: relative;
	top: 0;
	left: 0;
}


@media screen and (min-width: 651px) and (max-width: 850px){
	.sidebar.selected{
		display: none;
	}
}


@media screen and (min-width: 0px) and (max-width: 650px){
	.sidebar{
		display: none;
	}
}