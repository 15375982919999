/*
* @Author: Joe Steccato
* @Date:   2023-04-21 11:32:55
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-21 11:34:50
*/


/* POPUP BASIC */
.popupContainer{  
  position: fixed;  
  width: 100vw;  
  height: 100vh;  
  top: 0;  
  left: 0;  
  margin: auto;  
  background-color: rgba(0,0,0, 0.5);  
  z-index: 100;
}
