/*
* @Author: Joe Steccato
* @Date:   2023-07-13 12:50:41
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-13 13:19:31
*/

.main{
	height: 100%;
}

.header{
	color: white;
	width: calc(100vw - 340px);
}

.composer_row input {
    font-size: 11px;
/*    border: 2px solid #e3e3e3 !important;
*/    border-radius: 10px;
    width: 100%;
    padding-left: 6px;
}

/*		PRIMARY CONTAINERS		*/
div.composer_list_main{
	background-color: white;
	max-width: 1200px;
	width: calc(100vw - 20px);
	margin: auto;
}

div.composer_item_container{
	overflow-y: scroll;
	height: 72vh;
}


/*		PAGE HEADER			*/
div.composer_page_header{
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 70%;
	margin: 0 auto;
	margin-block: 0;
	height: 40px;	
}

div.composer_page_header h3{
	font-size: 24px;
	height: 40px;	
	margin-block: 0;
}

// div.composer_page_header div button.button{
// 	margin: auto;
// 	flex-grow: 1;
// 	width: 170px;
// }

// div button.button.btn-flat:hover {
//     background-color: #ffcf43;
//     color: #1d252c;
//     cursor: pointer;
//     border: 2px solid #1d252c;
//     border-radius: 7px;
// }

div.composer_page_header div:nth-child(2){
	margin: auto;
	flex-grow: 2;
}

/*		TABLE HEADER ROW		*/
div.header_row{
	line-height: 26px; 
	font-size: 16px; 
	font-weight: bold;
	background-color: #000 !important;
	color: #fff;
	border: 0px #000 solid !important;
	line-height: 40px !important;

	padding-right: 1.2%;
}

div.header_row div span{
	font-size: 10px;
	text-decoration: none !important;
}	

div.header_row div:hover{
	text-decoration: underline;
}

div.header_row div.selected_sort_column{
	color: #ffcf43;
}

div.header_row div.selected_sort_column:hover{
	text-decoration: none !important;
}

/*		COMPOSER ROW		*/
div.composer_row{
	display: flex; 
	flex-direction:row; 
	justify-content: flex-start; 
	line-height: 12px;
}

div.composer_row:hover{
	border: 1px #000 solid;
	cursor: pointer;
	background-color: #ffcf43;
}

div.row_color_0{
	background-color: #ccc;
	border: 1px #ccc solid;
}

div.row_color_1{
	background-color: #fff;
	border: 1px #fff solid;
}

div.composer_row.not_header .composer_paytype{
	font-size: 11px;
}

div.composer_row.not_header .composer_id{
		font-size: 12px;
		text-align: center;
		margin: auto 0;
}

div.composer_row .composer_id{
	width: 12%;
}

div.composer_row .composer_name{
	width: 45%;
}

div.composer_row .composer_fee{
	width: 6%;
}

div.composer_row .composer_paytype{
	width: 32%;
}

div.composer_row .composer_ark, div.composer_row .composer_4em{
	width: 5%;
}


div.composer_ark, div.composer_4em{
	margin: auto;
}

/*div.composer_row .composer_user_id{
	width: 20%;
}*/

/*		SVG		*/
div.composer_trash{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	cursor: pointer;
}

.composer_trash svg{
	width: 20px;
	fill: #00000033;
}

.composer_trash svg:hover{
	fill: #000000ff;
}