.main {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  color: #1d252c;
  background-color: #1d252c;
  overflow: hidden;
}

.btn-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
}


.home_menu_list{
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
  top: 40px;
}

.home_menu_list hr{
  margin: 10px 0;
}

.home_menu_list a div{
  padding: 6px 0px;
  width: 100%;
  background-color: #ffffffbb;
  border-top: 0.5px #1d252c77 solid;
  font-size: 20px;
  border-radius: 15px;
  margin: 6px 0;
}

.home_menu_list a:first-child div{
  border-top: none;
}

.home_menu_list a{
  height: 60px;
}

.home_menu_list a div, .home_menu_list a{
  color: black;
  text-decoration: none;
    
}

.home_menu_list a div:hover{
  background-color: #fff !important;
}
