/*
* @Author: Joe Steccato
* @Date:   2023-04-21 11:56:28
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-25 15:12:20
*/
.container{
	position: absolute;  
	width: 100%;  
	height: 100%;  
	top: 0;  
	left: 0;  
	right: 0;  
	bottom: 0;  
	background-color: rgba(0,0,0, 0) !important;  
	z-index: 100;
}

.menuList{
  position: relative;  
  top: 55px;  
  left: 0px;  
  width: 200px;
  height: auto;
  background-color: #1d252c;
  border-right: solid 1px #1d252c;
  border-bottom: solid 1px #1d252c;

  border-top: none;
  color: #ffffff75;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-weight: 300;
  letter-spacing: 1px;
  clip-path: inset(0px -10px -10px -10px);

  -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.5);  
  -moz-box-shadow:    0px 0px 8px 1px rgba(0,0,0,0.5);
  box-shadow:         0px 0px 8px 1px rgba(0,0,0,0.5);
}

.menuList a{
  padding: 6px 4px;
  background-color: #f6f6f6;
  border-top: 0.5px #d2d2d2 solid;
  text-decoration: none;
  color: #1d252c;
}

.menuList a:first-child{
  border-top: none;
}

.menuList a:hover{
  background-color: #ffcf43;
  color: #1d252c;
  font-weight: 500;
  text-decoration: none;
}

.spacer{
  height: 1px;
  background-color: #d2d2d2;
}