/*
* @Author: Joe Steccato
* @Date:   2023-04-20 15:01:03
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-07-17 13:54:27
*/

.container{
	margin: 30px auto;
}

.container h1{
	font-weight: 300;
	letter-spacing: 1.6px;
	font-size: 20px;
}

.container a{
	font-size: 14px;
	position: relative;
	top: -8px;
}