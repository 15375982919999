.submitContainer{
	display: flex;
	justify-content: center;
	flex-direction: center;
}

.submitBox{
	width: 100%;
	border-radius: 15px;
}

.submitInputContainer {
	padding: 10px 8px 2px 8px;
	min-height: 100px;
	height: auto;
	text-align: center;
}

.submitFiles{
	position: relative;
	opacity: 1;
}

button.addFilesButton{
	margin-top: 20px;
	padding: 8px 20px; 
	letter-spacing: 1.2px;
	border-radius: 0px;
	color: #c1c1c1;
	border: 1px solid #c1c1c1;
	background-color: #efefef;
}

button.addFilesButton:hover{
	background-color: #1d252c;
	color: #fff !important;
	cursor: pointer;
	border: 1px solid #1d252c;
}

.dontSubmitFiles{
	opacity: 0;
}

ol.fileOrderedList{
	list-style-type: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 !important;
	align-items: center;
	margin:  4px 0;
}

ol.fileOrderedList li{
/*	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 8px;
	padding-top: 4px;
	pointer-events: none;*/
}

ol.fileOrderedList li div{
	padding: 0px;
}

ol.fileOrderedList li div div{
	text-align: left;
}

ol.fileOrderedList div.filePercent{
	border: solid 1px black;
	height: 16px !important;
	min-width: 30px;
	width: 100%;
	width: auto;
	padding: 0;
	min-height: 16px;
	border-color: #1d252c;
	background-color: #acacac;
	border-radius: 15px;
	color: #fff;
	padding: 5px 8px;
	margin: 4px 0;
	font-size: 12px;
}

div.myDropZone a{
	text-decoration: underline;
	cursor: pointer;
	font-weight: 500;
	display: inline;
}

div.myDropZone a:hover{
	color: #ffcf43;
}

div.myDropZone{
	width: 100%;
	min-height: 100px;
	background-color:  #efefef;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-bottom: 10px;
}

div.myDropZone .myDropZoneInstructions{
	font-size: 14px;
	height:80px;
	margin: auto;
	width: 100%;
	line-height: 22px;
	font-weight: 300;
	letter-spacing: 1.2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	opacity: 0.6;
}

div.myDropZone{
    overflow: visible !important;
}

div.myDropZone:hover{
/*    border: 1px #1d252c solid !important;
*/}

button.selectFiles{
	width: 60%;
	height: 40px;
	padding: 6px 4px;
	margin-bottom: 10px;
}

.downloadIcon{
	text-align: right !important;
}

.downloadCancelButton, .failedFilesPopupCancelButton{
	color: #ffffff33;
	cursor: pointer;
	pointer-events: all;
}

.downloadCancelButton:hover, .failedFilesPopupCancelButton:hover{
	color: red;
}


/* FAILED FILES MINI POPUP */

div.failedFilesPopup{
	display: flex;
	flex-direction: column;
	justify-content: center;
	position:  absolute;
	top: 70vh;

	border: solid 1px #1d252c;
	background-color: #1d252c;
	color: white;
	z-index: 1000;
	box-shadow:1px 2px 4px #999;
}

div.failedFilesHeader{
	display: flex;
	flex-direction: row;
	justify-content: center;
	height:  36px;
}

div.failedIcon{
	width:  30px;
	font-size: 26px;
	background-color: #1d252c;
	color: #fff;
	padding:  0px 10px;
}

div.failedText, ul.failedFilesList{
	margin:  auto;
	font-size: 15px;
	padding:  10px;
}

div.failedFilesPopupCancelButton{
	padding: 0 10px;
	margin:  auto;
}

div.failedFilesPopupCancelButton:hover{
	color:  red;
}

ul.failedFilesList{
	margin: 6px 0;
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
}

ul.failedFilesList li{
	margin: 3px 5px;
	font-size: 12px;
}

div.uploadReminders{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

div.uploadReminders div{
	margin-bottom: 4px;
}


