.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  color: #1d252c;
/**/  overflow: hidden !important;
}
input[type='checkbox']:checked:after {
/*  content: '✔';*/
  background-color: #ffcf43;
}
.loginContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-y: scroll;
}

.loginContainer h1{
  margin: 40px 0;
}

.loginContainer div.loginBox{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #efefef;
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}

div.loginBox {
  height: 100%;
}

.loginBox form, .loginBox .resetPasswordForm{
  margin: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 400px;
}

.submitBox.loginBox form{
  margin-top: 10px;
}

div.loginBox form div{
/*  padding: 4px 10px;
*/}

div.loginTitle{
  padding: 20px;
  color: #fff;
  font-size: 24px;
  margin-top: -60px;
}

input.inputLogin {
  width: 70%;
  height: auto !important;
  padding: 14px 12px;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 0px !important;
  letter-spacing: 2px !important;
}

.loginInputContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.loginInputContainer input.loginSubmit{
  font-size: 14px;
  letter-spacing: 1px;
  width: 100px;
  height: 40px;
}

div.spacer {
  height: 200px;
}

div.loginErrorMsg{
  color: #F55;
  font-size: 14px;
  min-height: 20px;
  margin-bottom: 10px;
}

div.loginErrorMsg.loginErrorBold{
  font-weight: 500;
  color: #000;
  font-size: 14px;
  min-height: 20px;
  margin-bottom: 10px;
}

div.loginButtonsList{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  margin: auto;
}

.loginButtonRow{
  width: 100%;
/*  display: flex;
  flex-direction: row;
  justify-content: space-around;*/
  margin: 8px auto;

}

button.signUp{
  font-size: 14px !important;
  width: 100px;
  height: 40px;
}

.loginInputContainer input.loginSubmit, button.loginSubmit{
  background-color: #1d252c;
  border: solid #000 0px;
  color: #fff;
  border-radius: 0px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  letter-spacing: 1px;
}

.loginInputContainer input.loginSubmit:hover, button.loginSubmit:hover{
  background-color: #ffcf43;
  color: #1d252c;
}

.signUpPrompt{
  font-size: 12px;
}

.forgotPasswordLink{
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
}

.forgotPasswordLink:hover{
  color: #ffcf43;
}

div.loginLink{
    text-decoration: underline;
    cursor:  pointer;

}

div.loginLink:hover{
    color: #ffcf43;

}