/*
* @Author: Joe Steccato
* @Date:   2023-04-11 16:40:20
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-23 17:23:27
*/


.formHeader{
  margin: 0 20px 10px 20px;
  position: relative;
  padding-bottom: 0px;
  border-bottom: 0.5px solid #b7b7b7;
  padding-bottom: 10px;
}

.formHeader h2{
  padding-bottom: 8px;
  margin-block-end: 0.2em;
  margin-block-start: 0.5em;
}


.mobileCancel {
  display: none;
}

.trackButton{
	width: 50%;
}

.trackAddButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.linkButton {
    position: absolute;
    top: 10px;
    right: 30px !important;
    width: 18px;
    fill: #1d252c;
    opacity: 0.4;
    cursor: pointer;
}

.starButton {
    position: absolute;
    top: 10px;
    left: 30px !important;
    width: 18px;
    fill: #00000000;
    stroke: #414141;
    opacity: 0.6;
    cursor: pointer;
}

span.starButton:hover svg{
  stroke: #000;
  opacity: 1;
}

span.isStarred svg{
  stroke: #000;
  opacity: 1;
  fill: #ffcf43;
}


.svgButton:hover{
  fill: red !important;
  opacity: 0.7 !important;
}

// mobile
@media screen and (min-width: 0px) and (max-width: 600px){
  .mobileCancel{
    display: inline-block;
  }
}