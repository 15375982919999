/*
* @Author: Joe Steccato
* @Date:   2023-04-27 16:24:27
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-27 16:30:12
*/

.container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 15px 20px;
	padding: 6px;
	background-color: rgb(242, 242, 242);
	border-radius: 15px;
	border: 3px solid rgb(209, 209, 209);
}

.inner{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.svgBtn{
	width: 16px; 
	margin: 2px 10px;
}

.header{
	color: black;
	font-size: 14px;
	margin: 4px auto;
}

.tagsLabel{
	margin: 10px 0px;
	font-size: 12px;
}

.redKeyword{
	color: red; 
	font-size: 10px;
}

.tagging_svg{
  fill: #a1a1a1;
  cursor: pointer;
}

.tagging_svg:hover{
  fill: #000;
}

.taggerCopyButton{
  color:#a1a1a1;
}

.taggerCopyButton:hover{
  text-decoration: underline;
  color:#000;
}

.taggerCopyButton:hover .tagging_svg{
  fill: #000;
}

.rc-tooltip-inner{
  min-height: 20px;
  background-color: #1d252c;
  color: white;
}

