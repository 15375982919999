/*
* @Author: Joe Steccato
* @Date:   2023-04-13 17:20:25
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-17 13:20:56
*/

.editUserPopup{
	max-height: 80vh;
	height: 100%;
	width: 40vw;
	left: 2%;
	right: 2%;
	top: 10%;
	justify-content: flex-start;
	overflow-y: scroll;
}

.header{
	font-size: 26px;
	letter-spacing: 1.2px;
	font-weight: 300;
	color: black;
	margin: 15px 0px 30px 0px;
}


// INSTRUCTION BOX
.instructions{
	letter-spacing: 1px;
	background-color: white;
	padding: 6px;
	margin: auto;
	margin-bottom: 10px;
	color: #1d252c;
	width: 90%;
}

.instructions ul{
	text-align: left;
	font-size: 12px;
}

.instructions ul li{
	margin: 6px 0;
}

.red{
	color: red;
}


// USER FORM
.editUserForm{
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	color: rgb(0, 0, 0);
	width: 75%;
	max-width: 500px;
	margin: 0px auto;
}

.userField{
	display: flex;
	width: 100%;
	font-size: 16px;
	margin: 10px 0;
}

.userLabel{
	width: 30%;
	font-weight: 700;
	margin: auto 0;
	text-align: left;
	letter-spacing: 1px;
}

.userInput{
	width: 70%;
	text-align: left;
	font-weight: 300;
	letter-spacing: 1.2px;
}

.reactTags{
	width: 70% !important;
	padding: 6px;
}

.userField input.userInput, .reactTags{
	font-size: 16px;
    border: 2px solid #e3e3e3 !important;
    border-radius: 0px;
}

.userField input.userInput{
	padding: 6px;

}

.userAdminField#userAdminSelect{
	cursor: pointer;
}



.userAdminField label{
	margin: 10px auto;
	display: flex;
	flex-direction: row;
	width: 100px;
}

.errorMsg{
	font-size: 12px;
	color: red;
	margin-bottom: 16px;
}


// USER BUTTONS
.userButtons{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	margin: 4px 0;

}

.userButtons button{
	font-size: 16px;
	color: white;
	background-color: #1d252c;
	border: none;
	padding: 10px 16px;
	letter-spacing: 1px;
	cursor: pointer;
	font-weight: 300;
	margin: 0 5px;
}

.userButtons button.submitCreateButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}

.userButtons button.deleteButton:hover{
	background-color: #f00;
}

.buttonDivider{
	border-top: 1px solid #1d252c;
	height: 1px;
	width: 80%;
}

.userFieldID{
	margin: 10px auto;
	font-size: 10px;
	display: flex;
	flex-direction: row;
	color: #1d252c;
	width: auto;
}

.userFieldID .userLabel{
	text-align: center;
}

.saveSVG{
	margin-right: 5px;
}

.saveSVG svg{
	height: 14px;
	stroke: white;
	fill: white;

}

.userButtons button:hover .saveSVG svg{
	fill: #1d252c;
}

.saveSVG svg{
	

}
