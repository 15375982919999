/*
* @Author: Joe Steccato
* @Date:   2023-04-19 10:25:22
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-19 10:42:20
*/

.outer{
	height: calc(100vh - 97px);
}

.mainComposer{
	width: 100%;
	height: 100%;
	background-color: #fff;
}

.composerSubmissionView{
	height: 100%;
}