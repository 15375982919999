/*
* @Author: Joe Steccato
* @Date:   2023-04-20 10:51:17
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-16 17:57:03
*/

.container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 60px;
	overflow-y: hidden;
	background-color: #efefef;
	z-index: 5;
}

.container.showFormErrors{
	height: 80px;
}

.errorMessage{
	height: 20px;
	color: white;
	background-color: red;
	font-size: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-weight: 300;
	letter-spacing: 0.7px;
}

.buttonRow{
	max-width: 700px;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.composerButton{
	cursor: pointer;

	color: #fff;
	background-color: #1d252c;
	border: solid #000 0px;
	border-radius: 0px;

	min-width: 200px;
	width: 100px;
	height: 34px;
	margin: auto 20px;


	font-size: 14px;

	letter-spacing: 1.2px;
	font-weight: 300;
}

.composerButton.cancel{
	background-color: #fff;
	border: solid #000 0px;
	color: #1d252c;
}

.composerButton:hover{
	background-color: #ffcf43;
	color: #1d252c;
}

.composerButton.disabled, .composerButton.disabled:hover{
	opacity: 0.4 !important;
	cursor: not-allowed !important;
	color: #fff;
	background-color: #1d252c;
}