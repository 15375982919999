/*
* @Author: Joe Steccato
* @Date:   2023-04-26 17:34:14
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-04-26 18:45:41
*/


.searchBar{
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.searchBar input{
	  font-size: 20px;
	  width:100%;
	  padding: 10px 10px;
	  margin-bottom: 2px;
}

.clearButton, .searchButton {
	position: absolute;

  	right: 8px;
  	z-index: 3;
  	width: 30px;
  	height: 30px;
  	margin: auto 0;
  	vertical-align: middle;

  	opacity: 0.4;
}

.clearButton{
	top: 10px;
	right: 12px;

	width: 26px;
	height: 26px;
}

 .searchButton{
 	top: 7px;
 	right: 48px;
 }

.clearButton div, 
.clearButton div svg, {
	width: 26px;
	height: 26px;
	margin: auto 0;
}

.searchButton div,
.searchButton div svg{
	width: 32px;
	height: 32px;
	margin: auto 0;
}

.searchButton:hover,
.clearButton:hover{
	opacity: 1;
	cursor: pointer;
}


@media screen and (min-width: 0px) and (max-width: 600px){
	.searchBar{
		width: 100%;
	}

	.searchBar input{
		width: 100%;
		height: 24px;

		margin-top: 20px;

		padding: 8px 8px;
		font-size: 16px;
		margin-top: 20px;
		width: 100%!important;
		height: 24px;
		padding: 8px 8px !important;
		font-size: 16px !important;
	}

	.clearButton{
		top: 28px;
	}

	.searchButton{
		top: 25px;
	}
}