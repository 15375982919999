/* eslint-disable */
.main {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 56px);
  color: #1d252c;
  background-color: #1d252c;
  overflow: hidden;
}

.main.loading {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 80px);
  width: 100vw;
  color: #1d252c;
  background-color: #1d252c;
  overflow: hidden;
}

.checkBoxDiv{
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
