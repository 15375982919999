/*
* @Author: Joe Steccato
* @Date:   2023-05-17 17:07:26
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-05-17 17:47:39
*/

.fileInput{
	padding: 10px 4px;
    border-radius: 15px;
    border: solid 3px #d1d1d1;
    background-color: #f2f2f2;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.fileInput.stems{
	background-color: #ffb570;
}

.fileRow{
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin: 0 12px 0 10px;
	font-size: 12px;
	color: #707070;
}

.header{
	font-size: 11px;
	text-decoration: underline;

}

.name{
	width: calc(100% - 50px);
}

.checkboxes{
	width: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.checkbox{
 	width: 20px;
}

input.checkbox{
	margin: auto;
	width: 18px;
}

.addButton button{
	margin: auto
}

.name, .addFile textarea{
  	border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
    width: 90% !important;
    resize: none;
    border-radius: 8px;
    padding:3px;
    overflow: hidden !important;
}

.mp3File{
  	color: red;
}

.name:hover{
  	text-decoration: underline;
  	cursor: pointer;
}

.name:focus{
  	background-color: #fff;
  	text-decoration: none !important;
}

.addFile{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 26px;
	width: 90%;
	margin: auto;
	margin-top: 10px;

}

.addFile textarea{
	height: 26px;
	width: calc(100% - 50px);
	background-color: white;
}
